<template>
    <div class="main">
      <van-form >
        <van-cell-group title="申报状态">
          <van-field readonly label="企业名称" v-model="sbxxdata.merchant_name" />
          <van-field readonly label="申报状态" v-model="sydata.hzData.SBZT" />
          <van-field readonly label="缴款状态" v-model="sydata.hzData.YJSK" />

        </van-cell-group>

        <van-cell-group title="开票累计统计">
          <van-field readonly label="正数发票金额" v-model="kpxxData.TotalAmountForPosInv" />
          <van-field readonly label="正数发票税额" v-model="kpxxData.TotalTaxForPosInv" />
          <van-field readonly label="正数合计" v-model="kpxxData.hj_PosInv" />
          <van-field readonly label="红冲发票金额" v-model="kpxxData.TotalAmountForNegInv" />
          <van-field readonly label="红冲发票税额" v-model="kpxxData.TotalTaxForNegInv" />
          <van-field readonly label="红冲合计" v-model="kpxxData.hj_NegInv" />
        </van-cell-group>

        <van-cell-group title="作废累计统计">
          <van-field readonly label="正数发票金额" v-model="kpxxData.TotalAmountForInvalidatedPosInv" />
          <van-field readonly label="正数发票税额" v-model="kpxxData.TotalTaxForInvalidatedPosInv" />
          <van-field readonly label="正数合计" v-model="kpxxData.hj_InvalidatedPosInv" />
          <van-field readonly label="红冲发票金额" v-model="kpxxData.TotalAmountForInvalidateNegInv" />
          <van-field readonly label="红冲发票税额" v-model="kpxxData.TotalTaxForInvalidateNegInv" />
          <van-field readonly label="红冲合计" v-model="kpxxData.hj_InvalidateNegInv" />
        </van-cell-group>

        <van-cell-group title="份数统计">
          <van-field readonly label="正数发票" v-model="kpxxData.PositiveInvoiceCount" />
          <van-field readonly label="作废发票" v-model="kpxxData.InvalidatedPositiveInvoiceCount" />
          <van-field readonly label="红冲发票" v-model="kpxxData.NegativeInvoiceCount" />
          <van-field readonly label="红冲作废发票" v-model="kpxxData.InvalidatedNegativeInvoiceCount" />
          <van-field readonly label="期初库存" v-model="kpxxData.InitialInvoiceCount" />
          <van-field readonly label="期末库存" v-model="kpxxData.EndingInvoiceCount" />
          <van-field readonly label="领购发票" v-model="kpxxData.DownloadedInvoiceCount" />
          <van-field readonly label="退回发票" v-model="kpxxData.ReturnedInvoiceCount" />
          <van-field readonly label="空废发票" v-model="kpxxData.InvalidatedBlankInvoiceCount" />
        </van-cell-group>

      </van-form>
    </div>
    <!-- 遮罩loading -->
    <van-overlay :show="showload" @click="showload = false" >
        <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>
</template>

<script>
import {ref, onMounted, computed} from 'vue'
import { Form, Field, CellGroup } from 'vant';
import loginRequest from '../../api/login'
export default {
    name: "sbmx",
    setup() {
        const showload = ref(false);

        const sbxxdata =ref({});
        const kpxxData =ref({});
        const sydata = ref({
          bykpfs:0,
          byfs2:0,
          bykpje:0,
          byje2:0,
          byhcje:0,
          byhc2:0,
          jkje:0,
          hzData:{}
        });

        const sbxxReq = () =>{
          showload.value = true;
          // 获取开票信息
          loginRequest.yh_kpxx({}).then(res=>{
            // console.log(res);
            if (res.returnCode === "00"){
              kpxxData.value = res.rows.statistics;
            }
            //申报信息
            loginRequest.yh_sbxx({}).then(res=>{
              if (res.returnCode === "00"){
                console.log(res.rows);
                sbxxdata.value = res.rows;
                let t_hzData = res.rows.hzData[0];
                if(t_hzData!=undefined){
                  sydata.value.hzData = t_hzData;
                }
              }
              showload.value = false;

              console.log("info");
              console.log(sydata);
              console.log(sbxxdata);
              console.log(kpxxData);
            });

          });
        };



        onMounted(()=>{
          sbxxReq();
        });

        return{
            sbxxReq,
            showload,
            kpxxData,
            sbxxdata,
            sydata
        }
    }
}
</script>

<style scoped>
    .main {
        /* margin: 0; */
        padding:20px 10px;
        min-height: 100vh;
        background: #F7F8FA;
    }
    >>> .van-field__control {
        text-align: right;
    }

    
</style>